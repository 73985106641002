import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="background" />

      <header className="header">
        <h1 className="logo">Capsy</h1>
        <p className="tagline">Voyagez dans le temps avec vos souvenirs</p>
      </header>

      <main className="timeline">
        <div className="timeline-item">
          <div className="card">
            <span className="emoji">🌱</span>
            <h3>Inspiration</h3>
            <p>Trouvez l'idée qui mérite de traverser le temps.</p>
          </div>
        </div>
        <div className="arrow">→</div>

        <div className="timeline-item">
          <div className="card">
            <span className="emoji">📷</span>
            <h3>Création</h3>
            <p>Ajoutez vos souvenirs : texte, audio, vidéo, image.</p>
          </div>
        </div>
        <div className="arrow">→</div>

        <div className="timeline-item">
          <div className="card">
            <span className="emoji">🕰️</span>
            <h3>Choisissez une date</h3>
            <p>Programmez l'ouverture de votre capsule.</p>
          </div>
        </div>
        <div className="arrow">→</div>

        <div className="timeline-item">
          <div className="card">
            <span className="emoji">🔐</span>
            <h3>Sécurisation</h3>
            <p>Capsule scellée. Elle attend patiemment son heure.</p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
